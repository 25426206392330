import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

import Image from 'components/Image'
import { GTR } from 'styles/tokens'
import { H, Grid, Wrapper } from 'components/common'

const StyledImage = styled(Image)`
  margin-bottom: ${GTR.M};
`

const Team = () => {
  const { imageOne, imageTwo, imageThree, imageFour } = useStaticQuery(graphql`
    query {
      imageOne: file(relativePath: { eq: "cleo-and-clare.jpg" }) {
        ...fluidImage
      }
      imageTwo: file(relativePath: { eq: "containerville.jpg" }) {
        ...fluidImage
      }
      imageThree: file(relativePath: { eq: "frederico-and-claire.jpg" }) {
        ...fluidImage
      }
      imageFour: file(
        relativePath: { eq: "frederico-and-pritesh-with-team.jpg" }
      ) {
        ...fluidImage
      }
    }
  `)

  return (
    <Wrapper container>
      <H size="L" centered margin={[0, 0, 'XL']}>
        Our team
      </H>
      <Grid gapFromM={['XL', 'L']}>
        <Grid.Item spanFromM={6}>
          <StyledImage
            sizes={{ ...imageOne.childImageSharp.fluid, aspectRatio: 3 / 2 }}
            style={{ transform: 'scaleX(-1)' }}
          />
          <p>
            We set Pixel Pond up to put people first in the recruitment process,
            and internally our belief is no different.
          </p>
        </Grid.Item>
        <Grid.Item spanFromM={6}>
          <StyledImage
            sizes={{ ...imageTwo.childImageSharp.fluid, aspectRatio: 3 / 2 }}
          />
          <p>
            We are based in Containerville, East London and currently live in a
            40ft shipping container.
          </p>
        </Grid.Item>
        <Grid.Item spanFromM={6}>
          <StyledImage
            sizes={{ ...imageThree.childImageSharp.fluid, aspectRatio: 3 / 2 }}
          />
          <p>
            No one likes working with strangers, so we make an effort to get
            together as much as possible, and to make the most of our unique
            location.
          </p>
        </Grid.Item>
        <Grid.Item spanFromM={6}>
          <StyledImage
            sizes={{ ...imageFour.childImageSharp.fluid, aspectRatio: 3 / 2 }}
          />
          <p>
            The team you’ll meet is the core of the business, so investing in
            each other just makes sense.
          </p>
        </Grid.Item>
      </Grid>
    </Wrapper>
  )
}

export default Team
