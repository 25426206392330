import React from 'react'

import Layout from 'components/layout'
import SEO from 'components/seo'

import Hero from './Hero'
import Video from './Video'
import Team from './Team'
import Employers from './Employers'
import BiggerPicture from './BiggerPicture'
import LittleLondoners from './LittleLondoners'
import AffordableEducation from './AffordableEducation'
import PixelPride from './PixelPride'

const About = () => (
  <Layout>
    <SEO
      title="About us"
      description="People are our thing, we're here to help everyone be their best. Want to find out more, take a look."
    />
    <Hero />
    <Video />
    <BiggerPicture />
    <LittleLondoners />
    <PixelPride />
    <AffordableEducation />
    <Team />
    <Employers />
  </Layout>
)

export default About
