import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

import Image from 'components/Image'

import PATHS from 'routes/paths'

import CurveWrapper from 'components/CurveWrapper'
import RainbowButton from 'components/RainbowButton'
import { H, Grid, Wrapper } from 'components/common'
import { COLOR } from 'styles/tokens'

const PixelPride = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "nick-and-frederico-laughing.jpg" }) {
        ...fluidImage
      }
    }
  `)

  return (
    <CurveWrapper background={COLOR.ACCENT.PRIMARY}>
      <Wrapper container>
        <Grid>
          <Grid.Item spanFromM={6}>
            <Image fluid={image.childImageSharp.fluid} />
          </Grid.Item>
          <Grid.Item
            spanFromM={5}
            startColumnFromM={8}
            style={{ alignSelf: 'center' }}
          >
            <H size="L">Pixel Pride</H>
            <Wrapper margin={[0, 0, 'L']}>
              <p>
                Pride isn’t just for one month of the year. We’re growing a
                network called Pixel Pride - a group of digital advocates who
                can donate expertise and time to support charities within the
                LGBTQ+ community.
              </p>
            </Wrapper>
            <RainbowButton tag={Link} to={PATHS.PIXEL_PRIDE} wide stretchMobile>
              Find out more
            </RainbowButton>
          </Grid.Item>
        </Grid>
      </Wrapper>
    </CurveWrapper>
  )
}

export default PixelPride
