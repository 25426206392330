import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Image from 'components/Image'
import { H, Grid, Wrapper } from 'components/common'

const LittleLondoners = () => {
  const { imageOne, imageTwo } = useStaticQuery(graphql`
    query {
      imageOne: file(relativePath: { eq: "young-child-denim-jacket.jpg" }) {
        ...fluidImage
      }
      imageTwo: file(relativePath: { eq: "abracademy-img.png" }) {
        childImageSharp {
          fluid(maxWidth: 490) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Wrapper container>
      <Grid margin={[0, 0, 'XL']} marginFromM={[0, 0, 'XXL']}>
        <Grid.Item spanFromM={6}>
          <Image fluid={imageOne.childImageSharp.fluid} />
        </Grid.Item>
        <Grid.Item spanFromM={5} startColumnFromM={8}>
          <H size="M">Helping little Londoners</H>
          <p>
            The Kids Network is a community of children and young professionals
            connecting through fun, friendship and hardship for positive social
            change. We support children at a crucial time in their development
            by providing them with a volunteer mentor.
          </p>
          <p>
            We want more people to know about the amazing work organisations
            like these are doing, and encourage design leaders to transfer
            mentoring skills to the working world.
          </p>
        </Grid.Item>
      </Grid>
      <Grid>
        <Grid.Item startColumnFromM={8} spanFromM={5}>
          <Image
            fluid={imageTwo.childImageSharp.fluid}
            style={{ maxWidth: '490px', margin: '0 auto' }}
          />
        </Grid.Item>
        <Grid.Item spanFromM={6} style={{ alignSelf: 'center' }}>
          <H size="M">The most magical training you’ll ever do</H>
          <p>
            Organisations and employees face daily challenges: building trust,
            constantly innovating or collaborating productively.
          </p>
          <p>
            We’ve partnered with Abracademy, who use the power of magic to
            inspire change, and help people achieve the right mindset to work
            better, together.
          </p>
          <p>
            Abracademy works with two mindsets – belief and wonder. They
            underpin all their workshops (now available online) to inspire
            confidence and unleash creativity. ‘Magical Moments’ are a series of
            workshops that create moments of connection, elevation, insight and
            pride for teams. People return to work with renewed energy, deeper
            self awareness and stronger bonds.
          </p>
        </Grid.Item>
      </Grid>
    </Wrapper>
  )
}

export default LittleLondoners
