import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Image from 'components/Image'
import CurveWrapper from 'components/CurveWrapper'
import { Button, H, Grid, Wrapper } from 'components/common'
import { COLOR } from 'styles/tokens'

const Employers = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "pritesh-drawing-on-board.jpg" }) {
        ...fluidImage
      }
    }
  `)

  return (
    <CurveWrapper background={COLOR.ACCENT.PRIMARY} top={28}>
      <Wrapper container>
        <Grid>
          <Grid.Item spanFromM={6} startColumnFromM={7}>
            <Image
              sizes={{ ...image.childImageSharp.fluid, aspectRatio: 3 / 2 }}
            />
          </Grid.Item>
          <Grid.Item spanFromM={5} style={{ alignSelf: 'center' }}>
            <H size="L">Meet the founder</H>
            <Wrapper margin={[0, 0, 'L']}>
              <p>
                Pritesh Bhatt has over 15 years of experience in the recruitment
                industry, he’s committed to making great businesses better, not
                just bigger.
              </p>
              <p>
                Are you looking for someone new? Need advice on your employer
                brand or growing a more diverse team?
              </p>
            </Wrapper>
            <Button.White
              wide
              href="mailto:pritesh@pixel-pond.com"
              stretchMobile
            >
              Email Pritesh
            </Button.White>
          </Grid.Item>
        </Grid>
      </Wrapper>
    </CurveWrapper>
  )
}

export default Employers
