import React, { useEffect } from 'react'
import styled from 'styled-components'
import { screenMax } from 'styles/helpers/responsive'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import { Wrapper } from 'components/common'
import { GTR } from 'styles/tokens'

import pixelPride from 'images/pixel-pride-logo.jpg'
import abracademy from 'images/partners/abracademy-logo.png'
import dAndAD from 'images/partners/d-and-a-d-logo.png'
import kidsNetwork from 'images/partners/kids-network-logo.png'
import haus from 'images/partners/haus-logo.png'

const IMAGES = [
  { src: pixelPride, alt: 'Pixel Pride', width: 85 },
  { src: abracademy, alt: 'Abracademy', width: 250 },
  { src: dAndAD, alt: 'D&AD', width: 80 },
  { src: kidsNetwork, alt: 'The Kids Network', width: 270 },
  { src: haus, alt: 'Haus', width: 120 },
]

const StyledWrapper = styled(Wrapper)`
  ${screenMax.s`
    display: none;
  `}
`

const StyledImagesWrapper = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${GTR.XL};
`

const StyledImageWrapper = styled(motion.div)`
  &:not(:last-of-type) {
    margin-right: ${GTR.XL};
  }
`

const StyledImage = styled.img`
  flex: 0 1 auto;
  max-width: 100%;
`

const container = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delay: 0,
      when: 'beforeChildren',
      staggerChildren: 0.1,
    },
  },
}

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
}

const BiggerPicture = () => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  return (
    <StyledWrapper container>
      <StyledImagesWrapper
        variants={container}
        initial="hidden"
        animate={controls}
        ref={ref}
      >
        {IMAGES.map(({ src, alt, width }) => (
          <StyledImageWrapper key={alt} variants={item}>
            <StyledImage src={src} alt={alt} width={width} />
          </StyledImageWrapper>
        ))}
      </StyledImagesWrapper>
    </StyledWrapper>
  )
}

export default BiggerPicture
