import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Image from 'components/Image'
import CurveWrapper from 'components/CurveWrapper'

import { H, Grid, Wrapper } from 'components/common'
import { COLOR } from 'styles/tokens'

const LittleLondoners = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "experience-haus-hackathon.jpg" }) {
        ...fluidImage
      }
    }
  `)

  return (
    <>
      <Wrapper background={COLOR.BACKGROUND.PALE}>
        <Wrapper container containerPaddingBottom={0}>
          <Grid>
            <Grid.Item spanFromM={5}>
              <H size="M">Affordable education</H>
              <p>
                Self improvement shouldn’t be a luxury. Learning shouldn’t have
                to stop when we leave school or graduate. Experience Haus are a
                group of passionate creatives that host courses, workshops, and
                events for creatives. We work with them to connect students in
                the industry, and enable the most inquisitive minds to continue
                learning.
              </p>
            </Grid.Item>
            <Grid.Item spanFromM={6} startColumnFromM={7}>
              <Image fluid={image.childImageSharp.fluid} />
            </Grid.Item>
          </Grid>
        </Wrapper>
      </Wrapper>
      <CurveWrapper.Standalone background={COLOR.BACKGROUND.PALE} />
    </>
  )
}

export default LittleLondoners
