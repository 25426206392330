import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

import VimeoVideo from 'components/VimeoVideo'
import CurveWrapper from 'components/CurveWrapper'
import { Grid, Wrapper } from 'components/common'
import { COLOR, GTR, FONT } from 'styles/tokens'
import { screenMax } from 'styles/helpers/responsive'

const StyledQuote = styled.p`
  font-weight: ${FONT.WEIGHT.BOLD};
  font-size: ${FONT.SIZE.XXL};
  line-height: 1.3;
  margin-right: -${GTR.L};
  margin-left: -${GTR.L};

  ${screenMax.l`
    margin-right: 0;
    margin-left: 0;
  `}

  ${screenMax.m`
    font-size: ${FONT.SIZE.XL};
  `}

  && {
    ${screenMax.s`
      margin-top: ${GTR.M};
    `}
  }
`

const Video = () => {
  const { anant } = useStaticQuery(graphql`
    query {
      anant: file(relativePath: { eq: "anant-thumbnail.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <CurveWrapper background={COLOR.ACCENT.PRIMARY} top={44}>
      <Wrapper container>
        <Grid gap="XS">
          <Grid.Item spanFromM={6}>
            <VimeoVideo
              src="https://player.vimeo.com/video/322231423?app_id=122963&autoplay=1"
              title="Communication is Key"
              thumbnail={anant}
            />
          </Grid.Item>
          <Grid.Item
            spanFromM={5}
            startColumnFromM={8}
            style={{ alignSelf: 'center' }}
            centered
          >
            <StyledQuote>
              “I have so much trust in them to find me the right role”
            </StyledQuote>
            <p>Anant Bhadreshwara, Product Designer</p>
          </Grid.Item>
        </Grid>
      </Wrapper>
    </CurveWrapper>
  )
}

export default Video
