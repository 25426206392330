import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

import Image from 'components/Image'
import { H, Grid, Wrapper } from 'components/common'
import { COLOR } from 'styles/tokens'

const StyledWrapper = styled(Wrapper)`
  background-color: ${COLOR.GREY.DARK};
  color: ${COLOR.WHITE};
`

const Hero = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "woman-with-hair-wrap.jpg" }) {
        ...fluidImage
      }
    }
  `)

  return (
    <StyledWrapper>
      <Wrapper container>
        <H size="XL" centered margin={[0, 0, 'XL']} marginFromM={[0, 0, 'XXL']}>
          Great shouldn’t wait
        </H>
        <Grid>
          <Grid.Item spanFromM={6}>
            <H size="L" color={COLOR.ACCENT.PRIMARY}>
              The bigger picture
            </H>
            <p>
              We believe promoting diversity is vital to creative and successful
              workplaces, and it’s something we actively promote. It’s not about
              waiting for candidates to find their way to us, it’s our job to
              introduce talent from underrepresented backgrounds to the
              opportunities out there.
            </p>
            <p>
              Where someone is from, or how they self identify are powerful
              tools in challenging routine behavior, it’s essential for
              innovation and progression. We want to help challenge the
              stereotypes attached to roles, and make them both more appealing
              and more accessible to those who might not have previously
              considered them. Luckily we’re not the only people who feel like
              this, so we work with people like The Kid’s Network, D&AD,
              Abracademy and Experience Haus with the ambition to really make a
              change.
            </p>
          </Grid.Item>
          <Grid.Item spanFromM={5} startColumnFromM={8}>
            <Image fluid={image.childImageSharp.fluid} />
          </Grid.Item>
        </Grid>
      </Wrapper>
    </StyledWrapper>
  )
}

export default Hero
